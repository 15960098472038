<template>
  <v-container fluid class="pa-0 ma-0">
    <keep-alive>
      <component v-if="currentPage === 'listComp'" :is="currentPage"></component>
    </keep-alive>
    <component v-if="currentPage === 'viewComp'" :is="currentPage"></component>
  </v-container>
</template>

<script>
import listComp from '@/views/qna/list'
import viewComp from '@/views/qna/view'

export default {
  components: {
    listComp,
    viewComp
  },
  data: () => ({
    currentPage: 'listComp'
  }),
  watch: {
    // !!중요: /bbs 내부에서 라우트 변화를 감지하여 동적으로 변경해준다.
    '$route' (to, from) {
      // if (to.name === 'bbs' && to.params && to.params.id) {
      if (to.params && to.params.id) {
        // !! 정수인 아이디가 아닌 'LR' 이면 리스트 리프레시 해야한다
        // view.vue 에서 삭제 후 넘어온다!
        if (to.params.id === 'LR') {
          this.switchTo('listComp')
        } else {
          // !! 정상적으로 id 가 있으면 view 로 넘긴다.
          this.switchTo('viewComp')
        }
      } else {
        // !! id 가 없으면 리스트로
        this.switchTo('listComp')
      }
    }
  },
  mounted () {
    // !!중요: 외부에서 게시물 아이디와 함께 뷰페이지를 직접 접근할때는 아래 코드로 잡아야 함
    if (this.$router.history.current.params && this.$router.history.current.params.id) {
      // !! id 가 있으면 view 로 넘긴다.
      this.switchTo('viewComp')
    }
  },
  methods: {
    switchTo (page) {
      this.currentPage = page
    }
  }
}
</script>
